



























































































































import BaseInput from "@/components/UI/BaseInput.vue";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { required } from "@vuelidate/validators";
import { defineComponent, PropType, toRef } from "vue";
import useVuelidate from "@vuelidate/core";
import InputMask from "@/components/UI/InputMask.vue";
import AddressItemFtl from "@/components/order/addresses/AddressItemFtl.vue";
import PlaceOptions from "@/components/order/places/PlaceOptions.vue";
import Dimensions from "@/components/order/places/Dimensions.vue";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptionsItem.interface";

export default defineComponent({
  name: "PlaceItemFtl",
  components: {
    AddressItemFtl,
    Dimensions,
    PlaceOptions,
    BaseInput,
    InputMask
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    idx: { type: Number as PropType<number>, required: true },
    place: {
      type: Object as PropType<PlaceItemInterface>,
      required: true
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    readonlyDimensions: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    readonlyAssessedValue: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    options: {
      type: Object as PropType<OrderOptionsItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    "remove",
    "update:assessed-value",
    "update:place-dimension",
    "update:places-count",
    "add-loading-address",
    "add-place-with-loading-address",
    "add-place-with-unloading-address"
  ],
  setup(props, { emit }) {
    const place = toRef(props, "place");

    const v$ = useVuelidate(
      {
        loadingAddress: {
          required
        },
        unloadingAddress: {
          required
        }
      },
      place
    );

    const addPlaceWithLoadingAddress = () => {
      emit("add-place-with-loading-address");
    };

    const addPlaceWithUnloadingAddress = () => {
      emit("add-place-with-unloading-address");
    };

    const handleUpdatePlaceCount = (value: string) => {
      emit("update:places-count", value);
    };

    return {
      v$,
      addPlaceWithLoadingAddress,
      addPlaceWithUnloadingAddress,
      handleUpdatePlaceCount
    };
  }
});
