






















































































































































import BaseInput from "@/components/UI/BaseInput.vue";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { defineComponent, PropType, Ref, toRef } from "vue";
import { useTaxTypes } from "@/components/personItem/useTaxTypes";
import { usePersonValidation } from "@/components/personItem/usePersonValidation";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { usePersonInnFieldViewConfig } from "@/components/personItem/usePersonInnFieldViewConfig";
import CountrySelect from "@/components/order/city/CountrySelect.vue";
import InputMask from "@/components/UI/InputMask.vue";
import fullNameMask from "@/mixins/fullNameMask";
import { usePersonSearch } from "@/components/personItem/usePersonSearch";
import { usePersonMain } from "@/components/personItem/usePersonMain";

export default defineComponent({
  name: "DataEdoPerson",
  components: {
    InputMask,
    CountrySelect,
    BaseInput
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    person: {
      type: Object as PropType<PersonItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    organizationType: {
      type: Number as PropType<OrganizationTypeEnum>,
      required: true
    }
  },
  setup(props) {
    const _organizationType = toRef(props, "organizationType");
    const _person: Ref<PersonItemInterface> = toRef(props, "person");
    const _hasEdm: Ref<boolean> = toRef(props, "hasEdm");

    const { taxesTypes, getTaxType } = useTaxTypes();
    const {
      selectedPerson,
      changePersonType,
      setCountryCode,
      setInn
    } = usePersonMain(_person, _organizationType, taxesTypes);
    const {
      returnLabelForInn,
      returnMaskForInn
    } = usePersonInnFieldViewConfig();
    const {
      onOrgSearch,
      personList,
      searchQuery,
      isSearchLoading
    } = usePersonSearch(_person, _organizationType);
    const { v$ } = usePersonValidation(
      _person,
      _organizationType.value,
      _hasEdm
    );

    return {
      v$,
      taxesTypes,
      fullNameMask,
      searchQuery,
      personList,
      selectedPerson,
      isSearchLoading,
      getTaxType,
      setInn,
      changePersonType,
      returnLabelForInn,
      returnMaskForInn,
      onOrgSearch,
      setCountryCode
    };
  }
});
