
import { defineComponent, nextTick, PropType, toRefs } from "vue";
import {
  AuthorityToLoadEnum,
  LoaderItemContractDataInterface,
  LoaderItemEmployeeInterface,
  LoaderItemInterface
} from "@/models/person/LoaderItem.interface";
import useVuelidate, { ValidationArgs } from "@vuelidate/core";
import { helpers, minLength, requiredIf } from "@vuelidate/validators";
import { hasFullName } from "@/validations/person";
import DataEdoPerson from "@/components/order/data-edo/DataEdoPerson.vue";
import DatePicker from "@/components/UI/DatePicker.vue";
import DataEdoLoaderContractParty from "@/components/order/data-edo/DataEdoLoaderContractParty.vue";
import { startOfDay } from "date-fns";
import { formatDate, parseDateString } from "@/utils/dateUtils";
import DataEdoContact from "@/components/order/data-edo/DataEdoContact.vue";
import BaseInput from "@/components/UI/BaseInput.vue";

export default defineComponent({
  components: {
    BaseInput,
    DataEdoContact,
    DataEdoLoaderContractParty,
    DatePicker,
    DataEdoPerson
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    loader: {
      type: Object as PropType<LoaderItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { loader, hasEdm } = toRefs(props);

    const todayDateString = formatDate(new Date(), "yyyy-MM-dd");
    const v$ = useVuelidate<
      LoaderItemInterface,
      ValidationArgs<LoaderItemInterface>
    >(
      {
        employee: {
          fullName: {
            required: requiredIf(() => hasEdm.value && !loader.value.isSender),
            hasFullName
          },
          position: {
            required: requiredIf(() => hasEdm.value && !loader.value.isSender)
          },
          authorityToLoad: {
            required: requiredIf(() => hasEdm.value && !loader.value.isSender)
          },
          contractData: {
            title: {
              required: requiredIf(
                () =>
                  hasEdm.value &&
                  loader.value.employee.authorityToLoad ===
                    AuthorityToLoadEnum.Other &&
                  !loader.value.isSender
              )
            },
            number: {
              required: requiredIf(
                () =>
                  hasEdm.value &&
                  loader.value.employee.authorityToLoad ===
                    AuthorityToLoadEnum.Other &&
                  !loader.value.isSender
              )
            },
            contractDate: {
              required: requiredIf(
                () =>
                  hasEdm.value &&
                  loader.value.employee.authorityToLoad ===
                    AuthorityToLoadEnum.Other &&
                  !loader.value.isSender
              ),
              max: (value: string) => {
                if (!helpers.req(value)) return true;
                const date = parseDateString(value);
                if (date == null) return false;

                return date <= startOfDay(new Date());
              }
            },
            parties: {
              min: minLength(2)
            }
          } as ValidationArgs<LoaderItemContractDataInterface>
        } as ValidationArgs<LoaderItemEmployeeInterface>,
        reasonToAct: {
          title: {
            required: requiredIf(() => hasEdm.value && !loader.value.isSender)
          },
          number: {
            required: requiredIf(() => hasEdm.value && !loader.value.isSender)
          },
          contractDate: {
            required: requiredIf(() => hasEdm.value && !loader.value.isSender),
            max: (value: string) => {
              if (!helpers.req(value)) return true;
              const date = parseDateString(value);
              if (date == null) return false;

              return date <= startOfDay(new Date());
            }
          },
          parties: {
            min: minLength(2)
          }
        } as ValidationArgs<LoaderItemContractDataInterface>
      } as ValidationArgs<LoaderItemInterface>,
      loader
    );

    function onIsSenderChange(value: boolean) {
      if (value) return;
      nextTick(() => {
        v$.value.$touch();
      });
    }

    function onAuthorityToLoadChange(value: AuthorityToLoadEnum) {
      loader.value.employee.authorityToLoad = value;

      if (value !== AuthorityToLoadEnum.Other) return;

      nextTick(() => {
        v$.value.$touch();
      });
    }

    return {
      v$,
      todayDateString,
      onIsSenderChange,
      onAuthorityToLoadChange
    };
  }
});
