

































































































import { ListItemInterface } from "@/models/global/ListItem.interface";
import { defineComponent, PropType } from "vue";
import BaseInput from "@/components/UI/BaseInput.vue";
import SelectWithLazyLoading from "@/components/UI/SelectWithLazyLoading.vue";
import useVuelidate from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptionsItem.interface";
import { useSelectWithLazyLoading } from "@/models/searchAndPaging/useSelectWithLazyLoading";
import {
  getBodyTypes,
  getCargoCategories,
  getLoadingTypes
} from "@/api/infoteka";

export default defineComponent({
  name: "OrderOptions",
  components: { BaseInput, SelectWithLazyLoading },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    options: {
      type: Object as PropType<OrderOptionsItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const {
      searchQuery: bodyTypesSearchQuery,
      items: bodyTypes,
      isLoading: bodyTypesSearchLoading,
      onSearch: onSearchBodyType,
      onLoadMore: onLoadMoreBodyType
    } = useSelectWithLazyLoading(getBodyTypes);

    const {
      searchQuery: loadingTypesSearchQuery,
      items: loadingTypes,
      isLoading: loadingTypesSearchLoading,
      onSearch: onSearchLoadingType,
      onLoadMore: onLoadMoreLoadingType
    } = useSelectWithLazyLoading(getLoadingTypes);

    const {
      searchQuery: cargoCategoriesSearchQuery,
      items: cargoCategories,
      isLoading: cargoCategoriesSearchLoading,
      onSearch: onSearchCargoCategories,
      onLoadMore: onLoadMoreCargoCategories
    } = useSelectWithLazyLoading(getCargoCategories);
    const v$ = useVuelidate(
      {
        bodyTypes: {
          required,
          minLength: minLength(1)
        }
      },
      props.options
    );

    return {
      v$,
      bodyTypes,
      bodyTypesSearchQuery,
      bodyTypesSearchLoading,
      cargoCategories,
      cargoCategoriesSearchQuery,
      cargoCategoriesSearchLoading,
      loadingTypes,
      loadingTypesSearchQuery,
      loadingTypesSearchLoading,
      onSearchLoadingType,
      onSearchBodyType,
      onSearchCargoCategories,
      onLoadMoreLoadingType,
      onLoadMoreBodyType,
      onLoadMoreCargoCategories
    };
  },
  data() {
    return {
      temperatureRegimes: [
        {
          id: "Без режима",
          title: "Без режима"
        },
        {
          id: "2 — 6 °C",
          title: "2 — 6 °C"
        },
        {
          id: "12 — 18 °C",
          title: "12 — 18 °C"
        },
        {
          id: "5 — 25 °C",
          title: "5 — 25 °C"
        },
        {
          id: "0 — -5 °C",
          title: "0 — -5 °C"
        },
        {
          id: "-18 °C",
          title: "-18 °C"
        },
        {
          id: "-25 °C",
          title: "-25 °C"
        }
      ] as ListItemInterface[]
    };
  }
});
