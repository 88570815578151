
import { defineComponent, nextTick, PropType, toRefs } from "vue";
import { LoadingOwnerItemInterface } from "@/models/person/LoadingOwnerItem.interface";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import DataEdoPerson from "@/components/order/data-edo/DataEdoPerson.vue";
import DataEdoContact from "@/components/order/data-edo/DataEdoContact.vue";

export default defineComponent({
  components: { DataEdoContact, DataEdoPerson },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    loadingOwner: {
      type: Object as PropType<LoadingOwnerItemInterface>,
      required: true
    },
    hasEdm: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  setup(props) {
    const { loadingOwner, hasEdm } = toRefs(props);
    const v$ = useVuelidate(
      {
        noDataReason: {
          required: requiredIf(() => hasEdm && loadingOwner.value.isNoData)
        }
      },
      loadingOwner
    );

    function onIsSenderInput(val: boolean) {
      if (!val) {
        nextTick(() => {
          v$.value.$touch();
        });
        return;
      }

      props.loadingOwner.isNoData = false;
    }
    function onIsNoDataInput(val: boolean) {
      if (!val) {
        nextTick(() => {
          v$.value.$touch();
        });
        return;
      }

      props.loadingOwner.isSender = false;
    }

    return {
      onIsSenderInput,
      onIsNoDataInput,
      v$
    };
  }
});
